/** @jsx jsx */
import { jsx } from "@emotion/core";
import SplitPane from "react-split-pane";
import { FC } from "react";
import { Colors } from "@blueprintjs/core";

export const MainSideSplit: FC = ({ children }) => {
  return (
    <SplitPane
      split="vertical"
      minSize={400}
      defaultSize={400}
      primary="second"
      pane2Style={{
        backgroundColor: Colors.WHITE,
      }}
    >
      {children}
    </SplitPane>
  );
};
