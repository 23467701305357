/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Navbar } from "@blueprintjs/core";
import { FC } from "react";

export const MainNavbar: FC = () => {
  return (
    <Navbar>
      <Navbar.Group>
        <Navbar.Heading>
          <strong>初級教科書出現語彙チェッカー</strong>
        </Navbar.Heading>
      </Navbar.Group>
    </Navbar>
  );
};
