export type Morpheme = {
  surface: string;
  pos1: string;
  shogakuLemma: string | null;
};

export type AnalyzeReply = {
  moSeq: Morpheme[];
  levelMap: LevelMap;
};

export type LevelMap = {
  [shogakuLemma: string]: Level;
};

export type Level = 1 | 2 | 3 | 4 | 5;

const DEFAULT_BASE_URL =
  process.env.API_ENDPOINT || process.env.NODE_ENV === "production"
    ? "https://yukari3-api.overworks.jp/"
    : "http://10.100.1.40:3000/";

export default class Client {
  constructor(private base: string = DEFAULT_BASE_URL) {}

  async analyze(sentence: string, signal?: AbortSignal): Promise<AnalyzeReply> {
    const reply: AnalyzeReply = await fetch(
      new URL("/api/analyze", this.base).toString(),
      {
        method: "post",
        body: JSON.stringify({ sentence }),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        signal,
      },
    ).then((res) => res.json());
    return reply;
  }
}
