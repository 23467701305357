import { Colors } from "@blueprintjs/core";
import { Category } from "./category";

export const DefaultColorMap: ColorMap = {
  level5: Colors.COBALT2,
  level4: Colors.VIOLET3,
  level3: Colors.LIME2,
  level2: Colors.FOREST1,
  level1: Colors.ORANGE3,
  other: Colors.RED3,
  symbol: Colors.BLACK,
};

export type ColorMap = {
  [category in Category]: string;
};
