import { CategorizedMorpheme, CategorizeBySurface } from "~models/category";
import Client from "~api";

export type State =
  | { type: "initial" }
  | { type: "inflight"; abortController: AbortController }
  | { type: "completed"; morphemes: CategorizedMorpheme[] };

export async function request(text: string, { signal }: AbortController) {
  const client = new Client();
  const reply = await client.analyze(text, signal);
  const levelMap = new Map(Object.entries(reply.levelMap));
  const categorizeBySurface = new CategorizeBySurface(levelMap);
  const morphemes = reply.moSeq.map((m) => categorizeBySurface.categorize(m));
  return morphemes;
}
