/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { FC } from "react";
import { Colors, HTMLTable } from "@blueprintjs/core";
import { Category, CATEGORY_HEADINGS } from "~models/category";

const colorTableStyle = css`
  tr > td:first-child,
  tr > th:first-child {
    text-align: right;
  }
`;

const colorRadioButtonStyle = (color: string) => css`
  display: block;
  input[type="radio"] {
    position: absolute;
    visibility: hidden;
  }
  input[type="radio"] + label {
    display: flex;
    width: 24px;
    height: 24px;
    background-color: ${color};
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &:first-child label {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &:last-child label {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  input[type="radio"]:checked + label {
    &:after {
      content: " ";
      display: block;
      width: 10px;
      height: 10px;
      background-color: white;
      border-radius: 2px;
    }
  }
`;

interface ColorRadioButtonProps {
  name: string;
  color: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const ColorRadioButton: FC<ColorRadioButtonProps> = ({
  name,
  color,
  checked,
  onChange,
}) => {
  const id = encodeURIComponent(`${name}-${color}`);
  return (
    <div css={colorRadioButtonStyle(color)}>
      <input
        type="radio"
        id={id}
        value={color}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} />
    </div>
  );
};

const colorRadioButtonGroupStyle = css`
  display: flex;
  justify-content: center;
`;

const COLOR_SET = new Set([
  Colors.RED3,
  Colors.ORANGE3,
  Colors.FOREST1,
  Colors.LIME2,
  Colors.VIOLET3,
  Colors.COBALT2,
  Colors.GRAY5,
  Colors.ROSE5,
  Colors.BLACK,
]);

export type ColorMap = { [K in Category]: string };
export interface ColorPaletteProps {
  colorMap: ColorMap;
  onChange?: (colorMap: ColorMap) => void;
}
export const ColorPalette: FC<ColorPaletteProps> = ({ colorMap, onChange }) => {
  const handleChange = (
    id: Category,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.currentTarget.value;
    const newColorMap = Object.assign({}, colorMap, {
      [id]: value,
    });
    if (onChange) {
      onChange(newColorMap);
    }
  };
  return (
    <HTMLTable css={colorTableStyle} condensed striped>
      <thead>
        <tr>
          <th>分類</th>
          <th>表示色</th>
        </tr>
      </thead>
      <tbody>
        {[...CATEGORY_HEADINGS].map(([id, label]) => {
          const currentColor = colorMap[id];
          return (
            <tr key={id}>
              <td>{label}</td>
              <td>
                <form css={colorRadioButtonGroupStyle}>
                  {[...COLOR_SET].map((color) => (
                    <ColorRadioButton
                      key={color}
                      name={id}
                      color={color}
                      checked={color === currentColor}
                      onChange={(e) => handleChange(id, e)}
                    />
                  ))}
                </form>
              </td>
            </tr>
          );
        })}
      </tbody>
    </HTMLTable>
  );
};
