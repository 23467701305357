import { Morpheme, Level } from "../api";

export type Category =
  | "level1"
  | "level2"
  | "level3"
  | "level4"
  | "level5"
  | "symbol"
  | "other";
export type CategorizedMorpheme = Morpheme & {
  category: Category;
};

export class CategorizeBySurface {
  constructor(private levelMap: Map<string, Level>) {}

  getCategory(m: Morpheme): Category {
    switch (m.pos1) {
      case "補助記号":
      case "空白":
        return "symbol";
    }
    if (m.shogakuLemma === null) {
      return "other";
    }
    const level = this.levelMap.get(m.shogakuLemma);
    switch (level) {
      case 1:
        return "level1";
      case 2:
        return "level2";
      case 3:
        return "level3";
      case 4:
        return "level4";
      case 5:
        return "level5";
    }
    return "other";
  }

  categorize(m: Morpheme): CategorizedMorpheme {
    const category = this.getCategory(m);
    return { ...m, category };
  }
}

export type Summary = {
  // key of map is a query
  [C in Category]: Map<
    string,
    {
      label: string;
      count: number;
    }
  >;
};

export function summarize(ms: CategorizedMorpheme[]): Summary {
  const summary: Summary = {
    level1: new Map(),
    level2: new Map(),
    level3: new Map(),
    level4: new Map(),
    level5: new Map(),
    symbol: new Map(),
    other: new Map(),
  };
  for (const m of ms) {
    const map = summary[m.category];
    if (m.shogakuLemma === null) {
      continue;
    }
    if (!map.has(m.shogakuLemma)) {
      const label = m.shogakuLemma;
      map.set(label, { label, count: 0 });
    }
    const { label, count } = map.get(m.shogakuLemma)!;
    map.set(label, { label, count: count + 1 });
  }
  return summary;
}

export const CATEGORY_HEADINGS: Map<Category, string> = new Map<
  Category,
  string
>([
  ["other", "未使用"],
  ["level1", "1冊"],
  ["level2", "2冊"],
  ["level3", "3冊"],
  ["level4", "4冊"],
  ["level5", "5冊"],
  ["symbol", "記号"],
]);
