import React, { useState, FC, useEffect } from "react";
import { VerticalStack } from "~components/VerticalStack";
import { TextPane } from "~components/TextPane";
import { MainSideSplit } from "~components/MainSideSplit";
import { EditorCard } from "~components/EditorCard";
import { ColoredText } from "~components/ColoredText";
import { HighlightableEditor } from "~components/HighlightableEditor";
import { request, State } from "~hooks/yukari_api";
import { DefaultColorMap, ColorMap } from "~models/color_map";
import {
  Intent,
  Button,
  Card,
  Elevation,
  NonIdealState,
  Navbar,
  ButtonGroup,
  Divider,
  Callout,
  AnchorButton,
  H4,
} from "@blueprintjs/core";
import { Floating } from "~components/Floating";
import { IconNames } from "@blueprintjs/icons";
import {
  CategorizedMorpheme,
  CATEGORY_HEADINGS,
  summarize,
  Category,
} from "~models/category";
import { EditorLayout } from "~components/EditorLayout";
import { SummaryTree } from "./SummaryTree";
import { ColorPalette } from "~components/ColorPalette";
import { Scrollable } from "~components/Scrollable";
import { SideNavbar } from "~components/SideNavbar";
import { downloadSummaryCsv } from "~models/summary_csv";

const initilalColorMap = () => {
  const colorMapJson = localStorage.getItem("colorMap-v1");
  if (colorMapJson === null) {
    return DefaultColorMap;
  }
  try {
    const loaded = JSON.parse(colorMapJson);
    return Object.assign({}, DefaultColorMap, loaded);
  } catch (e) {
    localStorage.removeItem("colorMap-v1");
    return DefaultColorMap;
  }
};

const saveColorMap = (colorMap: ColorMap) => {
  localStorage.setItem("colorMap-v1", JSON.stringify(colorMap));
};

export const Checker = () => {
  const [text, setText] = useState("");
  const [isBold] = useState(true);
  const [colorMap, setColorMap] = useState(initilalColorMap());
  const [status, setStatus] = useState("initial" as State["type"]);
  const [abortController, setAbortController] = useState(new AbortController());
  const [morphemes, setMorphemes] = useState([] as CategorizedMorpheme[]);
  const [selectedQuerySet, setSelectedQuerySet] = useState(new Set<string>());
  const [expandedCategorySet, setExpandedCategorySet] = useState(
    new Set(CATEGORY_HEADINGS.keys()),
  );

  useEffect(() => {
    saveColorMap(colorMap);
  }, [colorMap]);

  const submit = async () => {
    const abortController = new AbortController();
    setAbortController(abortController);
    setStatus("inflight");
    try {
      const morphemes = await request(text, abortController);
      setMorphemes(morphemes);
      setStatus("completed");
    } catch {
      setStatus("initial");
    }
  };

  const abort = () => {
    abortController.abort();
  };

  const reset = () => {
    setMorphemes([]);
    setAbortController(new AbortController());
    setStatus("initial");
  };

  const handleSelect = (querySet: Set<string>) => {
    setSelectedQuerySet(querySet);
  };

  const handleExpansionChange = (categories: Set<Category>) => {
    setExpandedCategorySet(categories);
  };

  const expandAllCategories = () => {
    setExpandedCategorySet(new Set(CATEGORY_HEADINGS.keys()));
  };

  const collapseAllCategories = () => {
    setExpandedCategorySet(new Set());
  };

  const handleColorMapChange = (colorMap: ColorMap) => {
    setColorMap(colorMap);
  };

  const handleColorMapReset = () => {
    const ok = window.confirm("色設定を初期設定に戻しますか?");
    if (ok) {
      handleColorMapChange(DefaultColorMap);
    }
  };

  const handleColorMapReverse = () => {
    const newColorMap: ColorMap = {
      other: colorMap.level5,
      level1: colorMap.level4,
      level2: colorMap.level3,
      level3: colorMap.level2,
      level4: colorMap.level1,
      level5: colorMap.other,
      symbol: colorMap.symbol,
    };
    handleColorMapChange(newColorMap);
  };

  const coloredMorphemes = morphemes.map(
    ({ shogakuLemma, surface, category }) => {
      return {
        color: colorMap[category],
        surface,
        isHighlighted:
          shogakuLemma !== null && selectedQuerySet.has(shogakuLemma),
      };
    },
  );

  const summary = summarize(morphemes);

  const handleDownloadCsv = () => {
    downloadSummaryCsv(summary);
  };

  return (
    <VerticalStack>
      <MainSideSplit>
        <TextPane>
          <Callout style={{ width: 692, margin: "24px auto 0 auto" }}>
            文や単語をテキストボックスに入力した後、
            <AnchorButton
              small
              intent={Intent.PRIMARY}
              icon={IconNames.DIRECTION_RIGHT}
            />{" "}
            ボタンを押してください。
            <br />
            テキストボックス下に示した5冊の初級日本語教科書のうち、何冊に出現する単語かをチェックします。
            <br />
            ボックス内の単語は色別表示され、画面右側のスペースに語彙リストが表示されます。
            <br />
            語彙リストの中の単語をクリックすると、テキストボックス内の該当項目がハイライトされます。
            <br />
            再入力する際は
            <AnchorButton
              small
              intent={Intent.SUCCESS}
              icon={IconNames.EDIT}
            />{" "}
            ボタンを押してください。
          </Callout>
          <EditorLayout>
            <EditorCard width={640}>
              <HighlightableEditor
                isColored={status === "completed"}
                isInEdit={status === "initial"}
                isBold={isBold}
                value={text}
                onChange={(e) => setText(e.currentTarget.value)}
              >
                {coloredMorphemes && (
                  <ColoredText morphemes={coloredMorphemes} />
                )}
              </HighlightableEditor>
            </EditorCard>
            <ControlStrip>
              <ExecuteButton
                status={status}
                submit={submit}
                abort={abort}
                reset={reset}
              />
              <Divider />
              <DiscardButton reset={reset} text={text} onChange={setText} />
            </ControlStrip>
          </EditorLayout>
          {status === "completed" && (
            <Card
              elevation={Elevation.ZERO}
              style={{ margin: "24px auto", width: 692 }}
            >
              <div>
                <p>
                  テキストボックス内の表示色は、色をクリックして変更できます。
                </p>
                <ColorPalette
                  colorMap={colorMap}
                  onChange={handleColorMapChange}
                />
                <Button
                  icon={IconNames.EXCHANGE}
                  onClick={handleColorMapReverse}
                >
                  表示色を反転
                </Button>{" "}
                <Button onClick={handleColorMapReset} intent={Intent.WARNING}>
                  表示色を初期設定に戻す
                </Button>
              </div>
            </Card>
          )}
          <div style={{ margin: "24px auto", width: 692 }}>
            <H4>本チェッカーが対象としている教科書</H4>
            <ol>
              <li>
                スリーエーネットワーク（2012-2013）『みんなの日本語初級１，２』
              </li>
              <li>
                坂野永理，池田庸子，大野裕，品川恭子，渡嘉敷恭子（2020）『初級日本語げんきⅠ，Ⅱ』
              </li>
              <li>嶋田和子監修（2011）『できる日本語 初級』</li>
              <li>国際交流基金（2013-2014）『まるごと入門、初級１』</li>
              <li>
                東京外国語大学留学生日本語教育センター（2017）『大学生の日本語ともだち1，２』
              </li>
            </ol>
          </div>
          <p style={{ margin: "24px auto", width: 692 }}>
            本ツールは今後も教科書を追加する予定です。また、語彙リストやシステム上の不具合などに関しても随時修正を行います。ご理解の上ご利用ください。
          </p>
          <p style={{ margin: "24px auto", width: 692 }}>
            「初級日本語教科書語彙チェッカー」は、JSPS科研費18K00708の助成を受けて開発されました。また、このツールに使われている「初級日本語教科書出現語彙リスト」は東京外国語大学留学生日本語教育センター教育研究開発プロジェクト「初級教材の発展を目指した基本語彙リスト策定と多言語版開発」の支援を受けて作成した語彙リストを参考にしています。
          </p>
        </TextPane>
        <VerticalStack>
          <SideNavbar>
            <Button
              large
              icon={IconNames.DOWNLOAD}
              disabled={status !== "completed"}
              onClick={handleDownloadCsv}
            >
              CSV
            </Button>
            <Navbar.Divider />
            <ButtonGroup>
              <Button
                large
                minimal
                icon={IconNames.EXPAND_ALL}
                disabled={status !== "completed"}
                onClick={expandAllCategories}
              >
                語彙リスト展開
              </Button>
              <Button
                large
                minimal
                icon={IconNames.COLLAPSE_ALL}
                disabled={status !== "completed"}
                onClick={collapseAllCategories}
              >
                畳む
              </Button>
            </ButtonGroup>
          </SideNavbar>
          <Scrollable>
            {status === "completed" ? (
              <SummaryTree
                colorMap={colorMap}
                summary={summary}
                selectedQuerySet={selectedQuerySet}
                expandedCategorySet={expandedCategorySet}
                onSelect={handleSelect}
                onExpansionChange={handleExpansionChange}
              />
            ) : (
              <NonIdealState icon={IconNames.MANUAL} />
            )}
          </Scrollable>
        </VerticalStack>
      </MainSideSplit>
    </VerticalStack>
  );
};

const ControlStrip: FC = ({ children }) => {
  return (
    <Floating>
      <VerticalStack>{children}</VerticalStack>
    </Floating>
  );
};

interface ExecuteButtonProps {
  status: State["type"];
  submit: () => void;
  abort: () => void;
  reset: () => void;
}
const ExecuteButton: FC<ExecuteButtonProps> = ({
  status,
  submit,
  abort,
  reset,
}) => {
  if (status === "completed") {
    return (
      <Button
        large
        intent={Intent.SUCCESS}
        icon={IconNames.EDIT}
        onClick={() => {
          reset();
        }}
      />
    );
  } else {
    return (
      <Button
        large
        intent={Intent.PRIMARY}
        icon={IconNames.DIRECTION_RIGHT}
        onClick={() => {
          if (status === "inflight") {
            abort();
          } else {
            submit();
          }
        }}
        loading={status === "inflight"}
      />
    );
  }
};

interface DiscardButtonProps {
  reset: () => void;
  text: string;
  onChange: (text: string) => void;
}
const DiscardButton: FC<DiscardButtonProps> = ({ reset, text, onChange }) => {
  const [backup, setBackup] = useState(null as string | null);
  useEffect(() => {
    if (text.length !== 0) {
      setBackup(null);
    }
  }, [text]);
  const discard = () => {
    reset();
    setBackup(text);
    onChange("");
  };
  const restore = () => {
    if (backup !== null) {
      onChange(backup);
      setBackup(null);
    }
  };
  if (backup === null) {
    return (
      <Button
        large
        minimal
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
        onClick={discard}
        disabled={text.length === 0}
      />
    );
  } else {
    return <Button large minimal icon={IconNames.UNDO} onClick={restore} />;
  }
};
